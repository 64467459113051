module.exports = {
  analytics: {
    enable: true,
    debug: process.env.NODE_ENV !== "production",
    defaultSettings: {},
    plugins: [
      {
        name: "google-analytics",
        needConsent: false,
        settings: () => {
          return {
            measurementIds: [
              process.env.FRONT_COMMERCE_WEB_GOOGLE_ANALYTICS_MEASUREMENT_ID,
            ],
            gtagConfig: {
              anonymize_ip: false,
            },
          };
        },
        script: () => import("@analytics/google-analytics"),
      },
    ],
  },
};
